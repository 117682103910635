<template>
  <CContainer :fluid="true">
    <!-- MODALS -->
    <CModal
      class="overflow-auto ps__child--consume"
      :unmount-on-close="false"
      backdrop="static"
      v-if="stats && stats.autoPassesRoomsCount"
      :visible="state.modal.isVisible.autoPassLimit"
      :close-on-backdrop="false"
    >
      <div
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeModal(), resetAutoPassLimitForm()"
        >
          <span class="close-icon"></span>
        </div>
        <HelpCenterButton content-key="auto_pass_limit" :is-old="true" />
      </div>
      <div class="px-3">
        <h2 class="mb-4 text-center">Set Auto Pass limit</h2>
        <CreateAutoPassLimit
          ref="CreateAutoPassLimit"
          :limit="stats.autoPassLimit ? stats.autoPassLimit.limit : null"
          @auto-pass-limit-created="state.modal.isVisible.autoPassLimit = false"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <CRow>
      <CCol md="12" class="bg-white d-flex justify-content-center pt-0 pt-md-1">
        <div
          v-if="stats"
          class="passes-stats-container d-flex align-items-center justify-content-between px-2 px-sm-4"
        >
          <div v-if="!state.isLoading" class="passes-stats pt-2 pt-sm-0">
            <BuildingPasses />
            <MyPasses />
            <FlaggedPasses />
            <div
              v-if="stats.autoPassesRoomsCount"
              class="stat inverse icon-appended"
            >
              <div class="stat-content">
                Auto Passes
                <span>{{
                  stats.autoPassLimit
                    ? stats.autoPassesPreferencesCount +
                      " out of " +
                      stats.autoPassesRoomsCount
                    : "Off"
                }}</span>
              </div>
              <div class="icon" @click="setActiveModal('autoPassLimit')">
                <i class="flaticon-pen-1"></i>
              </div>
            </div>
            <div
              v-if="isActiveKioskModule"
              class="open-kiosk d-flex align-items-center ms-4"
            >
              <span class="stats-title me-2 ms-2">Kiosk:</span>
              <div class="icon">
                <i class="fi flaticon-monitor"></i>
                <span>{{ stats.myOpenKiosksCount }}</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="justify-content-center align-content-center mt-4 rounded-30 p-5 d-flex gap-2"
          >
            <CSpinner color="primary" />
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import CreateAutoPassLimit from "@/components/Forms/CreateAutoPassLimit.vue"
import BuildingPasses from "@/v3components/Dashboard/BuildingPassesOld.vue"
import FlaggedPasses from "@/v3components/Dashboard/FlaggedPassesOld.vue"
import MyPasses from "@/v3components/Dashboard/MyPassesOld.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import { useToast } from "vue-toastification"
import { computed, onMounted, reactive, ref, watch } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardCountersOld",
  components: {
    CreateAutoPassLimit,
    HelpCenterButton,
    BuildingPasses,
    MyPasses,
    FlaggedPasses
  },
  setup() {
    const toast = useToast()
    const store = useStore()

    const state = reactive({
      isLoading: true,
      activePassLimit: null,
      modal: {
        isVisible: {
          autoPassLimit: false,
          limitActivePass: false
        }
      }
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const waitingApprovalLength = computed(
      () => store.getters["dashboardCounters/waitingApprovalLength"]
    )

    const myActivePassesLength = computed(
      () => store.getters["dashboardCounters/myActivePassesLength"]
    )

    const stats = computed(() => store.getters["dashboardTable/stats"])

    const fetchDashboardCounterRelatedData = async () => {
      try {
        state.isLoading = true
        await store.dispatch("dashboardCounters/getPassesForCounters")
      } catch {
        toast.error(
          "Shucks! Something went wrong while fetching building passes data."
        )
      } finally {
        state.isLoading = false
      }
    }

    onMounted(async () => {
      await fetchDashboardCounterRelatedData()
      setPageTitle()
    })

    watch([waitingApprovalLength, myActivePassesLength], () => {
      setPageTitle()
    })

    const setActiveModal = (modal) => {
      if (modal) {
        state.modal.isVisible[modal] = true
      }
    }

    const setActivePassLimit = (limit) => {
      state.activePassLimit = limit
    }

    const closeModal = () => {
      state.modal = {
        isVisible: {
          autoPassLimit: false,
          limitActivePass: false
        }
      }
    }

    const CreateAutoPassLimit = ref()

    const resetAutoPassLimitForm = () => {
      if (CreateAutoPassLimit.value) {
        CreateAutoPassLimit.value.refreshForm()
      }
    }

    const setPageTitle = () => {
      document.title = `(${waitingApprovalLength.value}|${myActivePassesLength.value}) securlypass`
    }

    return {
      state,
      isActiveAppoinmentModule,
      isActiveKioskModule,
      waitingApprovalLength,
      myActivePassesLength,
      stats,
      setActiveModal,
      setActivePassLimit,
      closeModal,
      resetAutoPassLimitForm,
      CreateAutoPassLimit
    }
  }
}
</script>
