<template>
  <div
    v-if="tableTabs && tableTabs.length"
    class="consequences-ladder-datatable-container"
  >
    <InfoBox
      v-if="isLadderResetDateInThePast"
      class="mb-3 px-3 py-4 text-center mt-minus-5"
    >
      <div class="py-1">
        The reset date for this ladder is in the past. You can set a new reset
        date by clicking the pencil icon.
      </div>
    </InfoBox>
    <ActionPanel
      v-if="tableTabs"
      @tab-clicked="setActiveTab"
      class="mb-3"
      :tabs="tableTabs"
    >
      <template #right-content>
        <IconButton
          smaller
          icon="lh-normal ri-add-line"
          class="solid full-rounded"
          @click="openLadderInfractionForm"
        ></IconButton>
      </template>
    </ActionPanel>
    <DataTable
      v-if="activeLadder"
      :class="{
        'hide-table-body': !activeLadderSteps
      }"
      :items="activeLadderSteps || []"
      :fields="tableFields"
    >
      <template #type="{ item }">
        <td>
          <div>
            {{ item?.action?.name ? item.action.name : item.type }}
          </div>
        </td>
      </template>
      <template #grade_year="{ item }">
        <td>
          <div>
            {{
              item?.action?.grad_years ? item.action.grad_years.join(", ") : "-"
            }}
          </div>
        </td>
      </template>
      <template #actions="{ item }">
        <td class="p-1 text-end">
          <Actions
            v-if="item.order > maxReachedStepIndex"
            :actions="tableActions"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
        </td>
      </template>
      <template #footer>
        <tfoot>
          <tr class="w-full border-top-1">
            <td colspan="3">
              <div
                class="d-flex align-items-center justify-space-between footer-content"
              >
                <div class="flex-1 text-start d-flex align-items-center">
                  Reset date: {{ activeLadderResetDate }}
                  <i
                    @click="openEditLadderForm"
                    class="ri-edit-2-line ms-2 cursor-pointer"
                  ></i>
                  <div class="ms-5 reset-dates-dropdown" style="">
                    <CustomSelect
                      v-if="activeLadderResetDates"
                      :readonly="true"
                      :close-on-select="true"
                      :searchable="false"
                      placeholder="Previous reset dates"
                      v-model="state.ladderResetDatePlaceholder"
                      :options="activeLadderResetDates"
                    ></CustomSelect>
                  </div>
                </div>
                <div class="flex-1 text-end" v-if="!maxReachedStepIndex">
                  <IconButton
                    class="d-inline-flex"
                    @click="openDeleteLadderDialog"
                    icon="ri-delete-bin-line"
                  ></IconButton>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </template>
      <template #no-items-view><div></div></template>
    </DataTable>
  </div>
</template>

<script>
import { onMounted, computed, reactive, inject } from "vue"
import { useStore } from "vuex"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import CreateLadderInfractionForm from "@/v3components/Forms/CreateLadderInfractionForm.vue"
import CreateLadderForm from "@/v3components/Forms/CreateConsequencesLaddeForm.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"

export default {
  name: "TardyConsequencesLadderTable",
  components: {
    DataTable,
    ActionPanel,
    Actions,
    IconButton,
    InfoBox,
    CustomSelect
  },
  setup() {
    const store = useStore()
    const state = reactive({
      activeTab: null,
      ladderResetDatePlaceholder: "Previous reset dates"
    })
    const modal = inject("modal")
    const actionDialog = inject("actionDialog")

    // Ladders
    const ladders = computed(() => store.getters["consequencesLadder/ladders"])
    const ladderTypes = computed(
      () => store.getters["consequencesLadder/ladderTypes"]
    )
    const activeLadder = computed(() => {
      return ladders.value && state.activeTab
        ? ladders.value[state.activeTab.value]
        : null
    })
    const activeLadderSteps = computed(() => {
      return activeLadder.value && activeLadder.value.steps
        ? activeLadder.value.steps
        : null
    })
    const maxReachedStepIndex = computed(() => {
      return activeLadder.value && activeLadder.value.max_reached_step_index
        ? activeLadder.value.max_reached_step_index
        : null
    })
    const activeLadderResetDate = computed(() => {
      return activeLadder.value && activeLadder.value.reset_dates[0]
        ? activeLadder.value.reset_dates[0]
        : null
    })
    const activeLadderResetDates = computed(() => {
      return activeLadder.value && activeLadder.value.past_reset_dates.length
        ? ["Previous reset dates", ...activeLadder.value.past_reset_dates]
        : null
    })

    const isLadderResetDateInThePast = computed(() => {
      return !activeLadderResetDate.value && activeLadderResetDates.value
    })

    const getAllLadders = () => {
      store.dispatch("consequencesLadder/getAllLadders")
    }
    const deleteLadder = (id) => {
      store.dispatch("consequencesLadder/deleteladder", id).then(() => {
        store.commit("consequencesLadder/SET_LADDER_BY_TYPE", {
          type: state.activeTab.value,
          data: null
        })
      })
    }
    const cloneLaddersObject = () => {
      return JSON.parse(JSON.stringify(ladders.value))
    }
    const openDeleteLadderDialog = () => {
      actionDialog.open(deleteLadder, {
        args: activeLadder.value.id,
        props: {
          danger: true,
          title: "Delete this ladder",
          question: "Are you sure?"
        }
      })
    }
    const openEditLadderForm = () => {
      modal.open(CreateLadderForm, {
        size: "sm",
        title: "Edit reset date",
        props: {
          editableLadder: state.activeTab.value
        }
      })
    }
    const openLadderInfractionForm = (
      item = null,
      title = "Add new infraction"
    ) => {
      modal.open(CreateLadderInfractionForm, {
        size: "sm",
        title: title,
        props: {
          ladderType: state.activeTab.value,
          editableStep: item && item.order ? item : null
        }
      })
    }
    const openDeleteInfractionDialog = (item) => {
      actionDialog.open(deleteInfractions, {
        args: item.id,
        props: {
          danger: true,
          title: "Delete infraction step",
          question: "Are you sure?"
        }
      })
    }
    const deleteInfractions = (id) => {
      const laddersObject = cloneLaddersObject()
      laddersObject[state.activeTab.value].steps = laddersObject[
        state.activeTab.value
      ].steps.filter((el) => el.id !== id)
      store
        .dispatch("consequencesLadder/updateLadders", laddersObject)
        .then((res) => {
          const data = res.data
          if (data && data.data) {
            store.commit("consequencesLadder/SET_LADDERS", data.data)
          }
        })
    }
    // Table
    const tableTabs = computed(() =>
      ladderTypes.value
        ? ladderTypes.value.map((el) => {
            return {
              label: el == "both" ? "Combined (LTS & LTC)" : el,
              value: el
            }
          })
        : null
    )
    const tableFields = [
      {
        key: "order",
        _style: "width: 150px",
        label: "Infraction step",
        sorter: false,
        filter: false
      },
      { key: "type", label: "Action type", sorter: false, filter: false },
      { key: "grade_year", label: "Grade year", sorter: false, filter: false },
      {
        key: "actions",
        label: "Action",
        _style: "text-align:end; width: 50px",
        sorter: false,
        filter: false
      }
    ]
    const tableActions = [
      { label: "Edit", icon: "ri-checkbox-circle-line", action: "edit" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]
    const setActiveTab = (tab) => {
      state.activeTab = tab
    }
    const handleTableAction = (action, item) => {
      if (action.action === "delete") {
        openDeleteInfractionDialog(item)
      } else {
        openLadderInfractionForm(item, "Edit infractions")
      }
    }

    onMounted(() => {
      getAllLadders()
    })

    return {
      state,
      tableTabs,
      setActiveTab,
      openLadderInfractionForm,
      activeLadder,
      tableFields,
      tableActions,
      handleTableAction,
      ladders,
      openEditLadderForm,
      openDeleteLadderDialog,
      activeLadderSteps,
      isLadderResetDateInThePast,
      activeLadderResetDates,
      activeLadderResetDate,
      maxReachedStepIndex
    }
  }
}
</script>
