import passConstants from "@/constants/passConstants"
import visitorConstants from "@/constants/visitorConstants"
import moment from "moment-timezone"
import store from "@/store/index"
import { cloneDeep } from "lodash"

const helpers = {
  currTzDateTime: (date, time, format = "YYYY-MM-DD HH:mm") => {
    let newDate = new Date(date).toLocaleDateString("en-GB") + " " + time
    newDate = moment(newDate, "DD-MM-YYYY HH:mm")
    return helpers.transformDateTime(newDate, format)
  },
  currTzDate: (date, format = "MM/DD/YYYY") => {
    let newDate = new Date(date).toLocaleDateString("en-GB")
    newDate = moment(newDate, "DD-MM-YYYY")
    return helpers.transformDate(newDate, format)
  },
  getTommorowDate: (format = "MM/DD/YYYY") => {
    let newDate = new Date().toLocaleDateString("en-GB")
    newDate = moment().add(1, "days").format(format).toString()
    return newDate
  },
  getToday: (format = "MM/DD/YYYY hh:mm A") => {
    return moment().format(format).toString()
  },
  transformUTC(date, format = "MM/DD/YYYY hh:mm A") {
    return moment.utc(date).tz(moment().tz()).format(format)
  },
  transformDateTime: (date, format, zone) => {
    const _date = moment(date).format(format ? format : "MM/DD/YYYY HH:mm")
    const _zone = zone ? zone : " " + moment().tz()
    return _date + _zone
  },
  getHTMLDateTime: (
    date,
    formatDate = "MM/DD/YYYY",
    formatTime = "HH:mm",
    separator = "&nbsp;&nbsp;"
  ) => {
    //readonly
    const _date = moment(date).format(formatDate)
    const _time = moment(date).format(formatTime)
    return _date + separator + _time
  },
  transformDate: (date, format) => {
    return moment(date).format(format ? format : "MM/DD/YYYY")
  },
  addMinutesToTime: (time, minutes, fromFormat, toFormat) => {
    return moment(time, fromFormat ? fromFormat : "HH:mm")
      .add(minutes, "minutes")
      .format(toFormat ? toFormat : "HH:mm")
  },
  removeMinutesFromTime: (time, minutes) => {
    return moment(time, "HH:mm").subtract(minutes, "minutes").format("HH:mm")
  },
  formatDate(date, formatFrom, formatТо) {
    return formatFrom
      ? moment(date, formatFrom).format(formatТо)
      : moment(date).format(formatТо)
  },
  date(date) {
    return date ? moment(date) : moment()
  },
  getEndOfTheDay() {
    return moment().format("YYYY-MM-DD 23:59:59")
  },
  secondsToTimeString(SECONDS) {
    return new Date(SECONDS * 1000).toISOString().substr(11, 8)
  },
  checkForNotificationSound(payload = null) {
    const currentUser = store.getters["authentication/user"]
    const isUserMuted = !currentUser.audio_enabled
    let play = !isUserMuted

    if (payload && Object.hasOwnProperty.call(payload, "notifyAdultsIds")) {
      if (Array.isArray(payload.notifyAdultsIds)) {
        play = !isUserMuted && payload.notifyAdultsIds.includes(currentUser.id)
      } else {
        play = false
      }
    }
    if (play) {
      this.playNotificationSound()
    }
  },
  playNotificationSound() {
    const notificationsAudio = document.getElementById("notifications-audio")
    if (notificationsAudio) {
      notificationsAudio.currentTime = 0
      notificationsAudio.muted = true //Chrome problem fix
      notificationsAudio.play()
      notificationsAudio.muted = false //Chrome problem fix
    }
  },
  stopNotificationSound() {
    const notificationsAudio = document.getElementById("notifications-audio")
    if (notificationsAudio) {
      notificationsAudio.pause()
      notificationsAudio.currentTime = 0
    }
  },
  scrollToTop() {
    const container = document.getElementsByClassName(
      "main-scroll-container"
    )[0]
    container.scrollTo({
      top: 0,
      left: 0
    })
  },
  setModalToggleClass(value) {
    const body = document.getElementsByTagName("body")
    if (value) {
      body[0].classList.add("modal-opened")
    } else {
      body[0].classList.remove("modal-opened")
    }
  },
  getBadgeFlags(pass) {
    const flags = {
      active: false,
      canceled: false,
      edited: false,
      ended: false,
      extended: false,
      min_time: false,
      missed: false,
      missed_request: false,
      system_completed: false,
      waiting_approval: false,
      limit_exceeded: false
    }
    // Waiting approval
    if (
      !pass.approved_at &&
      !pass.expired_at &&
      !pass.canceled_at &&
      pass.pass_status == 1
    ) {
      flags.waiting_approval = true
    }
    //extended FE check =

    if (pass.child) {
      if (
        pass.child.extended_at !== null &&
        pass.child.system_completed == 1 &&
        pass.pass_status == 0
      ) {
        flags.extended = true
      }
    }

    if (
      pass.extended_at !== null &&
      pass.completed_at &&
      pass.system_completed == 1
    ) {
      flags.extended = true
    }

    if (
      pass.extended_at &&
      pass.system_completed == 1 &&
      pass.pass_status == 0
    ) {
      flags.extended = true
    }
    //system_ended FE check =
    if (
      pass.child === null &&
      pass.system_completed == 1 &&
      pass.extended_at == null
    ) {
      flags.system_completed = true
    }

    if (
      pass.child &&
      pass.extended_at == null &&
      pass.child.system_completed == 1 &&
      pass.child.extended_at == null
    ) {
      flags.system_completed = true
    }

    if (
      pass.child &&
      pass.child.type === "APT" &&
      pass.child.expired_at !== null &&
      !pass.child.system_completed &&
      pass.child.extended_at === null
    ) {
      flags.system_completed = true
    }
    //Cancelled
    if (pass.canceled_at) {
      flags.canceled = true
    }
    //Edited
    if (pass.edited_at !== null) {
      flags.edited = true
    }
    //Active
    if (
      (pass.pass_status == 1 && pass.approved_at) ||
      (pass.child && pass.child.pass_status == 1)
    ) {
      flags.active = true
    }
    //Ended
    if (pass.child) {
      if (
        pass.child.approved_at &&
        !pass.child.pass_status &&
        !pass.child.canceled_at &&
        !pass.child.expired_at
      ) {
        flags.ended = true
      }

      if (
        pass.child.extended_at &&
        pass.child.system_completed &&
        !pass.pass_status &&
        !pass.child.pass_status
      ) {
        flags.ended = true
      }
    }

    if (!pass.child) {
      if (
        pass.completed_at &&
        pass.pass_status == 0 &&
        !pass.canceled_at &&
        !pass.expired_at
      ) {
        flags.ended = true
      }

      if (pass.extended_at && pass.completed_at && pass.system_completed) {
        flags.ended = true
      }

      if (pass.extended_at && pass.system_completed && !pass.pass_status) {
        flags.ended = true
      }
    }

    //min_time
    if (
      (pass.flagged_at !== null && !pass.child && !pass.extended_at) ||
      (pass.child && pass.flagged_at && !pass.child.extended_at)
    ) {
      flags.min_time = true
    }

    if (pass.child && pass.child.flagged_at && !pass.child.extended_at) {
      flags.min_time = true
    }

    if (pass.extended_at && pass.flagged_at && pass.pass_status) {
      flags.min_time = true
    }

    if (pass.child && pass.extended_at && pass.flagged_at) {
      flags.min_time = true
    }

    if (
      pass.child &&
      pass.child.flagged_at &&
      pass.child.extended_at &&
      pass.child.pass_status
    ) {
      flags.min_time = true
    }

    //missed FE check:

    if (
      pass.expired_at &&
      !pass.pass_status &&
      !pass.system_completed &&
      !pass.approved_at
    ) {
      flags.missed = true
    }

    if (Number(pass?.limit_exceeded) === 1) {
      flags.limit_exceeded = true
    }

    return flags
  },
  isPassSystemEnded(pass) {
    return (
      (!pass.parent &&
        pass.completed_by === null &&
        pass.completed_at !== null &&
        pass.type !== "SLP") ||
      (pass.parent &&
        pass.approved_by === null &&
        pass.approved_at !== null &&
        pass.completed_at === null &&
        pass.completed_by === null &&
        pass.parent &&
        pass.type !== "SLP") ||
      (pass.parent &&
        pass.completed_at !== null &&
        pass.completed_by == null &&
        pass.type !== "SLP")
    )
  },
  getTotalTime(pass) {
    if (pass.approved_at && pass.completed_at && !pass.child) {
      const diff = new Date(
        moment
          .duration(moment(pass.completed_at).diff(moment(pass.approved_at)))
          .asMilliseconds()
      )
        .toISOString()
        .substr(11, 8)
      //diff between pass.approved_at and pass.child.completed_at in HH:mm:ss format
      return diff
    }

    if (
      pass.child &&
      pass.child.completed_at &&
      pass.child.approved_at &&
      pass.approved_at
    ) {
      const diff = new Date(
        moment
          .duration(
            moment(pass.child.completed_at).diff(moment(pass.approved_at))
          )
          .asMilliseconds()
      )
        .toISOString()
        .substr(11, 8)
      //diff between pass.approved_at and pass.child.completed_at in HH:mm:ss format
      return diff
    }

    if (
      pass.child &&
      !pass.child.completed_at &&
      pass.child.approved_at &&
      pass.approved_at
    ) {
      //diff between pass.approved_at and pass.child.approved_at in HH:mm:ss format
      const diff = new Date(
        moment
          .duration(
            moment(pass.child.approved_at).diff(moment(pass.approved_at))
          )
          .asMilliseconds()
      )
        .toISOString()
        .substr(11, 8)
      return diff
    }
  },
  setUserAssignedRoomsPerPass(pass) {
    const usersAssignedRooms =
      store.getters["dashboardTable/usersAssignedRooms"]
    if (pass.from) {
      pass.from.user_ids_assigned =
        usersAssignedRooms[pass.from.trip_type ? "rooms" : "users"][
          pass.from.id
        ] || []
    }
    if (pass.to) {
      pass.to.user_ids_assigned =
        usersAssignedRooms[pass.to.trip_type ? "rooms" : "users"][pass.to.id] ||
        []
    }
    if (pass.child != null) {
      pass.child.to.user_ids_assigned =
        usersAssignedRooms[pass.child.to.trip_type ? "rooms" : "users"][
          pass.child.to.id
        ] || []
      pass.child.from.user_ids_assigned =
        usersAssignedRooms[pass.child.from.trip_type ? "rooms" : "users"][
          pass.child.from.id
        ] || []
    }
    return pass
  },
  isMyPass(pass) {
    //counts as my pass if I am assigned to location and it doesn't matter if  interacted with it
    const user = store.getters["authentication/user"]
    if (pass.approved_by === user.id || pass.completed_by === user.id) {
      return true
    }

    if (pass.from) {
      if (
        (pass.from_type === "App\\Models\\User" && pass.from.id === user.id) ||
        (pass.from_type === "App\\Models\\Room" &&
          pass.from.user_ids_assigned &&
          pass.from.user_ids_assigned.includes(user.id))
      ) {
        return true
      }
    }

    if (pass.to) {
      if (
        (pass.to_type === "App\\Models\\User" && pass.to.id === user.id) ||
        (pass.to_type === "App\\Models\\Room" &&
          pass.to.user_ids_assigned &&
          pass.to.user_ids_assigned.includes(user.id))
      ) {
        return true
      }
    }

    if (pass.child) {
      if (
        pass.child.approved_by === user.id ||
        pass.child.completed_by === user.id
      ) {
        return true
      }

      if (pass.child.from) {
        if (
          (pass.child.from_type === "App\\Models\\User" &&
            pass.child.from.id === user.id) ||
          (pass.child.from_type === "App\\Models\\Room" &&
            pass.child.from.user_ids_assigned &&
            pass.child.from.user_ids_assigned.includes(user.id))
        ) {
          return true
        }
      }

      if (pass.child.to) {
        if (
          (pass.child.to_type === "App\\Models\\User" &&
            pass.child.to.id === user.id) ||
          (pass.child.to_type === "App\\Models\\Room" &&
            pass.child.to.user_ids_assigned &&
            pass.child.to.user_ids_assigned.includes(user.id))
        ) {
          return true
        }
      }
    }
    return false
  },
  doesCountMyPass(pass) {
    //counts as my pass if I am assigned to location and i interacted with it
    const user = store.getters["authentication/user"]
    if (pass.approved_by === user.id || pass.completed_by === user.id) {
      return true
    }

    if (pass.from) {
      if (
        (pass.from_type === "App\\Models\\User" && pass.from.id === user.id) ||
        (pass.from_type === "App\\Models\\Room" &&
          pass.from.user_ids_assigned &&
          pass.from.user_ids_assigned.includes(user.id) &&
          pass.approved_by === user.id)
      ) {
        return true
      }
    }

    if (pass.to) {
      if (
        (pass.to_type === "App\\Models\\User" && pass.to.id === user.id) ||
        (pass.to_type === "App\\Models\\Room" &&
          pass.to.user_ids_assigned &&
          pass.to.user_ids_assigned.includes(user.id) &&
          pass.completed_by === user.id)
      ) {
        return true
      }
    }

    if (pass.child) {
      if (
        pass.child.approved_by === user.id ||
        pass.child.completed_by === user.id
      ) {
        return true
      }

      if (pass.child.from) {
        if (
          (pass.child.from_type === "App\\Models\\User" &&
            pass.child.from.id === user.id) ||
          (pass.child.from_type === "App\\Models\\Room" &&
            pass.child.from.user_ids_assigned &&
            pass.child.from.user_ids_assigned.includes(user.id) &&
            pass.child.approved_by === user.id)
        ) {
          return true
        }
      }

      if (pass.child.to) {
        if (
          (pass.child.to_type === "App\\Models\\User" &&
            pass.child.to.id === user.id) ||
          (pass.child.to_type === "App\\Models\\Room" &&
            pass.child.to.user_ids_assigned &&
            pass.child.to.user_ids_assigned.includes(user.id) &&
            pass.child.completed_by === user.id)
        ) {
          return true
        }
      }
    }
    return false
  },
  filterByTab(pass) {
    const activePassState = store.getters["dashboardTable/getStatus"]
    const isActiveDashboardUpdate =
      store.getters["dashboardTable/isActiveDashboardUpdate"]
    const allowedInActivePasses =
      store.getters["dashboardTable/allowedInActivePasses"]

    if (activePassState != "active" && pass.canceled_at) {
      return false
    }
    if (activePassState == "both") {
      return true
    }
    if (activePassState == "active" && !isActiveDashboardUpdate) {
      return allowedInActivePasses.includes(pass.id)
    } else {
      if (activePassState == "active" && pass.pass_status == 1) {
        return true
      }
      if (
        activePassState == "active" &&
        pass.child &&
        pass.child.pass_status == 1
      ) {
        return true
      }
    }

    if (activePassState == "ended" && pass.pass_status == 0 && !pass.child) {
      return true
    }
    if (
      activePassState == "ended" &&
      pass.pass_status == 0 &&
      pass.child.pass_status == 0
    ) {
      return true
    }
    return false
  },
  filterAptPassesByTab(aptPass, aptTypeParam) {
    const aptType = aptTypeParam
      ? aptTypeParam
      : store.getters["dashboardTable/getType"]

    const user = store.getters["authentication/user"]
    //for appts on the Dashboard
    if (aptType != "appointment") {
      const userAssignedRooms = this.getUserAssignedRooms(user)
      const isToUser =
        aptPass.to_type === "App\\Models\\User" && aptPass.to.id === user.id
      const isToRoom =
        aptPass.to_type === "App\\Models\\Room" &&
        userAssignedRooms &&
        userAssignedRooms.includes(aptPass.to.id)
      const isFromUser =
        aptPass.from_type === "App\\Models\\User" && aptPass.from.id === user.id
      const isFromRoom =
        aptPass.from_type === "App\\Models\\Room" &&
        userAssignedRooms &&
        userAssignedRooms.includes(aptPass.from.id)
      const isConfirmed = !!aptPass?.confirmed_by_user || false
      if (
        //only for today's appointments
        aptType == "appointments_today" &&
        !aptPass.waiting_activation &&
        !aptPass.is_ended &&
        !aptPass.is_missed &&
        !aptPass.is_today_waiting_confirmation &&
        !aptPass.is_activated &&
        !aptPass.canceled_from_dashboard &&
        !aptPass.is_long_running &&
        aptPass.individual_pass_state != "RUNNING" &&
        aptPass.individual_pass_state != "LONG_RUNNING" &&
        (moment(aptPass.for_date).isSame(moment(), "day") ||
          aptPass.is_missed_request) &&
        (isToUser || isToRoom || ((isFromUser || isFromRoom) && isConfirmed))
      ) {
        return true
        //for today_awaiting and future_awaiting
      } else {
        if (
          (aptPass.to_type === "App\\Models\\User" &&
            aptPass.to.id === user.id) ||
          (aptPass.to_type === "App\\Models\\Room" &&
            userAssignedRooms &&
            userAssignedRooms.includes(aptPass.to.id))
        ) {
          if (
            aptType == "appointments_future_awaiting" &&
            !aptPass.is_missed_request &&
            aptPass.is_future_waiting_confirmation
          ) {
            return true
          }
          if (
            aptType == "appointments_awaiting" &&
            !aptPass.is_missed_request &&
            aptPass.is_today_waiting_confirmation
          ) {
            return true
          }
        }
      }
    }
    return false
  },
  getUserAssignedRooms(user) {
    const allUsersAssignedRooms =
      store.getters["dashboardTable/usersAssignedRooms"]
    if (
      user &&
      allUsersAssignedRooms &&
      allUsersAssignedRooms.users &&
      allUsersAssignedRooms.users[user.id]
    ) {
      return allUsersAssignedRooms.users[user.id]
    }
  },
  sortPassesByColumn(column, a, b) {
    const date = moment().add(1, "day")
    if (column == "user.last_name:asc") {
      return a.user.last_name.localeCompare(b.user.last_name)
    }
    if (column == "user.last_name:desc") {
      return b.user.last_name.localeCompare(a.user.last_name)
    }
    if (column == "elastic_advanced_fields.approve_time_only:asc") {
      return (
        moment(a.approved_at || date).valueOf() -
        moment(b.approved_at || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.approve_time_only:desc") {
      return (
        moment(b.approved_at || date).valueOf() -
        moment(a.approved_at || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.completed_time_only:asc") {
      return (
        moment(a.completed_at || date).valueOf() -
        moment(b.completed_at || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.completed_time_only:desc") {
      return (
        moment(b.completed_at || date).valueOf() -
        moment(a.completed_at || date).valueOf()
      )
    }

    if (column == "elastic_advanced_fields.approve_child_time_only:asc") {
      return (
        moment((a.child && a.child.approved_at) || date).valueOf() -
        moment((b.child && b.child.approved_at) || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.approve_child_time_only:desc") {
      return (
        moment((b.child && b.child.approved_at) || date).valueOf() -
        moment((a.child && a.child.approved_at) || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.completed_child_time_only:asc") {
      return (
        moment((a.child && a.child.completed_at) || date).valueOf() -
        moment((b.child && b.child.completed_at) || date).valueOf()
      )
    }
    if (column == "elastic_advanced_fields.completed_child_time_only:desc") {
      return (
        moment((b.child && b.child.completed_at) || date).valueOf() -
        moment((a.child && a.child.completed_at) || date).valueOf()
      )
    }
    if (column == "total_time:asc") {
      const left = a.total_time ? parseInt(a.total_time.replaceAll(":", "")) : 0
      const right = b.total_time
        ? parseInt(b.total_time.replaceAll(":", ""))
        : 0
      return left - right
    }
    if (column == "total_time:desc") {
      const left = a.total_time ? parseInt(a.total_time.replaceAll(":", "")) : 0
      const right = b.total_time
        ? parseInt(b.total_time.replaceAll(":", ""))
        : 0
      return right - left
    }
    if (column == "type:asc") {
      return a.type.localeCompare(b.type)
    }
    if (column == "type:desc") {
      return b.type.localeCompare(a.type)
    }
    return b.id - a.id
  },
  isUserAssignedToRoom(aptPass) {
    const user = store.getters["authentication/user"]
    const userAssignedRooms = this.getUserAssignedRooms(user)
    return (
      (aptPass.to_type === "App\\Models\\User" && aptPass.to.id === user.id) ||
      (aptPass.to_type === "App\\Models\\Room" &&
        userAssignedRooms &&
        userAssignedRooms.includes(aptPass.to.id))
    )
  },
  aptPassStatus(pass) {
    const user = store.getters["authentication/user"]
    const allUsersAssignedRooms =
      store.getters["dashboardTable/usersAssignedRooms"]
    const activeAppoinmentModuleOptions =
      store.getters["schools/activeAppoinmentModuleOptions"]
    const activeTab = store.getters["adultAptPass/activeTab"] // "next 7 days, beyond 7 days"
    const activePassType = store.getters["dashboardTable/getType"]
    const studentActiveTab = store.getters["studentAptPass/activeTab"]
    let usersAssignedRooms = []
    if (
      user &&
      allUsersAssignedRooms &&
      allUsersAssignedRooms.users &&
      allUsersAssignedRooms.users[user.id]
    ) {
      usersAssignedRooms = allUsersAssignedRooms.users[user.id]
    }
    //Define is_missed
    pass.is_missed = pass.individual_pass_state === "MISSED"
    //Define is_canceled
    if (pass.individual_pass_state === "CANCELED" || pass.canceled_at) {
      pass.is_canceled = true
    } else {
      pass.is_canceled = false
    }
    //Define waiting_activation
    pass.waiting_activation =
      pass.individual_pass_state === "WAITING_ACTIVATION"
    //Define is_for_confirm
    pass.is_for_confirm = false
    if (
      !pass.confirmed_by_teacher_at &&
      !pass.canceled_at &&
      pass.individual_pass_state !== "MISSED" &&
      pass.created_by_user &&
      pass.created_by_user.role_id == 1 &&
      moment(pass.for_date).isAfter(moment(), "second")
    ) {
      // if it's to them allow confirm
      if (pass.to_type == "App\\Models\\User" && pass.to.id == user.id) {
        pass.is_for_confirm = true
      }
      if (
        pass.to_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.to.id)
      ) {
        pass.is_for_confirm = true
      }
      //Mark as for confirm if it's student
      if (
        pass.created_by_user &&
        pass.created_by_user.role_id == 1 &&
        user.role.id == 1
      ) {
        pass.is_for_confirm = true
      }
    }
    //Define is_for_acknowledge
    pass.is_for_acknowledge = false
    if (
      !pass.canceled_at &&
      pass.individual_pass_state != "RUNNING" &&
      pass.individual_pass_state != "LONG_RUNNING" &&
      pass.individual_pass_state != "MISSED" &&
      pass.individual_pass_state != "ENDED" &&
      pass.individual_pass_state != "CANCELED" &&
      pass.individual_pass_state != "WAITING_ACTIVATION" &&
      pass.confirmed_by_teacher_at != null &&
      !pass.acknowledged_by_user &&
      moment(pass.for_date).isAfter(moment(), "second")
    ) {
      // if the pass it's from system
      if (pass.from.id === 0) {
        pass.is_for_acknowledge = true
      }
      if (
        pass.from_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.from.id)
      ) {
        // If the user is related with that location
        pass.is_for_acknowledge = true
      }
      // If it's related to the user
      if (pass.from_type === "App\\Models\\User" && pass.from.id === user.id) {
        pass.is_for_acknowledge = true
      }
    }
    //Define is_ended
    pass.is_ended = pass.individual_pass_state == "ENDED"

    //Define can_be_canceled
    pass.can_be_canceled = false
    if (
      !pass.canceled_at &&
      (pass.individual_pass_state == "IN_ANOTHER_PASS" ||
        pass.individual_pass_state == "NEW") &&
      activePassType === "appointment" &&
      activeTab === "prevSevenDays"
    ) {
      pass.can_be_canceled = true
    }
    if (
      !pass.canceled_at &&
      (pass.individual_pass_state == "IN_ANOTHER_PASS" ||
        pass.individual_pass_state == "NEW") &&
      moment(pass.for_date).isAfter(moment(), "second")
    ) {
      // if it's from system
      if (pass.from.id === 0) {
        pass.can_be_canceled = true
      }
      // If it's related to the user
      if (pass.from_type === "App\\Models\\User" && pass.from.id === user.id) {
        pass.can_be_canceled = true
      }
      // If it's related to the user
      if (pass.to_type === "App\\Models\\User" && pass.to.id === user.id) {
        pass.can_be_canceled = true
      }
      // If it's in assigned location ad it's from them, we allow cancel
      if (
        pass.from_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.from.id)
      ) {
        pass.can_be_canceled = true
      }
      if (
        pass.to_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.to.id)
      ) {
        pass.can_be_canceled = true
      }
      if (
        (user.role_id == 2 || user.role_id == 4) &&
        activeAppoinmentModuleOptions &&
        !activeAppoinmentModuleOptions.admin_edit
      ) {
        pass.can_be_canceled = true
      }
      if (pass.created_by === user.id) {
        pass.can_be_canceled = true
      }
    }
    //Define is_for_today
    if (
      moment().isSame(moment(pass.for_date), "day") &&
      (pass.individual_pass_state == "NEW" ||
        pass.individual_pass_state != "CANCELED") &&
      pass.individual_pass_state != "ENDED" &&
      (pass.confirmed_by_teacher_at != null ||
        pass.recurrence_appointment_pass_id != null)
    ) {
      pass.is_for_today = true
    } else {
      pass.is_for_today = false
    }
    //Define is_for_future
    if (
      pass.individual_pass_state == "NEW" &&
      moment(pass.for_date).isAfter(moment(), "day") &&
      pass.confirmed_by_teacher_at != null
    ) {
      pass.is_for_future = true
    } else {
      if (
        pass.individual_pass_state == "NEW" &&
        moment(
          pass.recurrence_appointment_pass &&
            pass.recurrence_appointment_pass.recurrence_end_at
        ).isSameOrAfter(moment(), "day") &&
        ((activeTab === "prevSevenDays" && user.role_id != 1) ||
          (studentActiveTab === "prevSevenDays" && user.role_id == 1))
      ) {
        pass.is_for_future = true
      } else {
        pass.is_for_future = false
      }
    }
    //Define is_missed_request
    pass.is_missed_request = false
    if (
      moment(pass.for_date).isBefore(moment(), "second") &&
      !pass.confirmed_by_teacher_at &&
      !pass.canceled_at &&
      !pass.recurrence_appointment_pass
    ) {
      pass.is_missed_request = true
    }
    //Define awaiting confirmation
    pass.is_today_waiting_confirmation = false
    if (
      moment(pass.for_date).isSameOrAfter(moment(), "second") &&
      moment().isSame(moment(pass.for_date), "day") &&
      pass.individual_pass_state == "NEW" &&
      pass.confirmed_by_teacher_at == null &&
      this.isUserAssignedToRoom(pass)
    ) {
      pass.is_today_waiting_confirmation = true
    }
    //Define future awaiting confirmation
    pass.is_future_waiting_confirmation = false
    if (
      moment(pass.for_date).isAfter(moment(), "day") &&
      pass.individual_pass_state == "NEW" &&
      pass.confirmed_by_teacher_at == null &&
      this.isUserAssignedToRoom(pass)
    ) {
      pass.is_future_waiting_confirmation = true
    }
    //Define can_be_edited
    pass.can_be_edited = false
    if (
      !pass.canceled_at &&
      (pass.individual_pass_state == "IN_ANOTHER_PASS" ||
        pass.individual_pass_state == "NEW") &&
      activePassType === "appointment" &&
      activeTab === "prevSevenDays"
    ) {
      pass.can_be_edited = true
    }
    if (
      !pass.canceled_at &&
      pass.individual_pass_state == "NEW" &&
      moment(pass.for_date).isAfter(moment(), "second")
    ) {
      // If the user is admin and admin_edit option is checked we can edit the apt
      if (
        user.role_id === 2 &&
        activeAppoinmentModuleOptions &&
        !activeAppoinmentModuleOptions.admin_edit
      ) {
        pass.can_be_edited = true
      }
      // If the auth user is related with the location "From"
      if (
        pass.from_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.from.id)
      ) {
        pass.can_be_edited = true
      }

      // If the auth user is related with the location "To"
      if (
        pass.to_type === "App\\Models\\Room" &&
        usersAssignedRooms.includes(pass.to.id)
      ) {
        pass.can_be_edited = true
      }

      // If the pass is from system
      if (pass.from.id === 0) {
        pass.can_be_edited = true
      }

      // If it's created by the user
      if (pass.created_by === user.id) {
        pass.can_be_edited = true
      }

      // If it's related to the user
      if (pass.from_type === "App\\Models\\User" && pass.from.id === user.id) {
        pass.can_be_edited = true
      }

      // If it's related to the user
      if (pass.to_type === "App\\Models\\User" && pass.to.id === user.id) {
        pass.can_be_edited = true
      }
    }
    //Define Activated
    pass.is_activated = pass.individual_pass_state == "RUNNING"
    pass.is_long_running = pass.individual_pass_state == "LONG_RUNNING"
    //Define is_in_another_pass
    pass.is_in_another_pass = pass.individual_pass_state == "IN_ANOTHER_PASS"

    return pass
  },
  isDateInSevenDays(day) {
    return moment(day).isBetween(
      moment(),
      moment().endOf("day").add(7, "days", "[]")
    )
  },
  isAptInSelectedTab(aptPass) {
    const activePassType = store.getters["dashboardTable/getType"]
    const activeTab = store.getters["adultAptPass/activeTab"]

    return (
      activePassType.includes("appointment") &&
      ((activeTab === "nextSevenDays" &&
        this.isDateInSevenDays(aptPass.for_date)) ||
        (activeTab === "prevSevenDays" &&
          !this.isDateInSevenDays(aptPass.for_date)))
    )
  },
  isAptInSelectedTabStudent(aptPass) {
    const activeTab = store.getters["studentAptPass/activeTab"]
    return (
      (activeTab === "nextSevenDays" &&
        this.isDateInSevenDays(aptPass.for_date)) ||
      (activeTab === "prevSevenDays" &&
        !this.isDateInSevenDays(aptPass.for_date))
    )
  },
  stringDateRange(start, end) {
    return start.format("MM/DD/YYYY") + " - " + end.format("MM/DD/YYYY")
  },
  minSchoolYearDate() {
    const now = moment()
    const currentMonth = now.month()
    if (currentMonth < 6) {
      return new Date(now.year() - 1, 6, 1)
    }

    return new Date(now.year(), 6, 1)
  },
  maxSchoolYearDate() {
    const now = moment()
    const currentMonth = now.month()
    if (currentMonth < 6) {
      return new Date(now.year(), 5, 30)
    }
    return new Date(now.year() + 1, 5, 30)
  },
  isPreviousYearDate(date) {
    return (
      moment(this.currTzDate(date)) <
      moment(this.currTzDate(this.minSchoolYearDate()))
    )
  },
  isPassExceedingSystemEndTime(pass) {
    const approvedAtPlusEndTime = this.getPassEndTime(pass)

    const status = pass.child ? pass.child.pass_status : pass.pass_status

    return status == 1 && moment().isAfter(approvedAtPlusEndTime)
  },
  getPassEndTime(pass) {
    const approvedAtPlusEndTime = moment(pass.approved_at)

    const expireTime = pass.expire_after.split(":")

    approvedAtPlusEndTime.add(expireTime[0], "hours")
    approvedAtPlusEndTime.add(expireTime[1], "minutes")
    approvedAtPlusEndTime.add(expireTime[2], "seconds")

    return approvedAtPlusEndTime
  },
  clearVisitorLocalStorage() {
    const keys = [
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.USER,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.STOP_AUTO_DETECT,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS
        .SHOW_VISITOR_SIGNED_IN_LIST_MODAL,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.DEFAULT_CAMERA,
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.FCM_TOKEN
    ]

    keys.forEach((key) => localStorage.removeItem(key))
  },
  numberValidator(value) {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/
    return regex.test(value)
  },
  alphaSpaceValidator(value) {
    const regex = /^[A-Za-z\s]+$/
    return regex.test(value)
  },
  alphaNumSpaceSpecialCharacterValidator(value) {
    const regex = /^[A-Za-z0-9\s\-,&:_@.]+$/
    return regex.test(value)
  },
  phoneNumberFormatter(number) {
    if (helpers.numberValidator(number)) {
      return number
    }
    let str = number.toString().replace(/\D/g, "")
    while (str.length < 10) {
      str = str + "0"
    }
    return `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6)}`
  },
  downloadFileByPath: (filePath) => {
    const link = document.createElement("a")
    link.href = filePath
    link.target = "_blank"
    link.download = filePath.split("/").pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  getCurrentTimezoneName: () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const smallCaseTimeZone = timeZone?.trim()?.toLowerCase()
    const map = {
      "asia/calcutta": "Asia/Kolkata"
    }
    return map[smallCaseTimeZone] || timeZone
  },
  convertToCurrentTimezone: (
    dateTime,
    isUTC = true,
    format = "MM-DD-YYYY h:mm A",
    returnDateOnly = false
  ) => {
    const adjustedDateTime = isUTC ? moment.utc(dateTime) : moment(dateTime)
    const currentTimezone = helpers.getCurrentTimezoneName()
    const dateTimePart = adjustedDateTime.tz(currentTimezone).format(format)
    const datePart = dateTimePart?.split?.(" ")?.[0] || ""
    return returnDateOnly ? datePart : dateTimePart
  },
  stringDateRangeWithTimezone: (start, end) => {
    const startDate = helpers.convertToCurrentTimezone(
      start,
      false,
      "MM/DD/YYYY",
      true
    )
    const endDate = helpers.convertToCurrentTimezone(
      end,
      false,
      "MM/DD/YYYY",
      true
    )
    return `${startDate} - ${endDate}`
  },
  imageUrlToBase64: async (url) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        const pngData = base64data.replace(
          /^data:.*,/,
          "data:image/png;base64,"
        )
        resolve(pngData)
      }
      reader.onerror = reject
    })
  },
  getFormattedDateWithoutTimezone: (date, format = "MM-DD-YYYY") => {
    return moment(date).format(format)
  },
  execPromiseWithTimeout: (promise, timeout, message) => {
    return Promise.race([
      promise,
      new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error(message || "Operation timed out")),
          timeout
        )
      )
    ])
  },
  isPassActive: (pass) => {
    return (
      Number(pass?.child?.pass_status) === 1 || Number(pass?.pass_status) === 1
    )
  },
  /**
   * Updates and filters a pass based on the specified type.
   *
   * @param {Object} pass - The pass object to update and filter.
   * @param {string} type - The type of filter to apply. Must be one of the passConstants.PASS_TYPE_NAMES values.
   * @returns {boolean} - Returns true if the pass matches the filter criteria, otherwise false.
   */
  updateAndFilterPassByPassType: (pass, type) => {
    let updatedPass = cloneDeep(pass)
    updatedPass.badge_flags = helpers.getBadgeFlags(updatedPass)
    updatedPass.total_time = helpers.getTotalTime(updatedPass)
    updatedPass = helpers.setUserAssignedRoomsPerPass(updatedPass)

    switch (type) {
      case passConstants.PASS_TYPE_NAMES.TOTAL:
        // Filter for total passes excluding those waiting for approval or missed
        return (
          !updatedPass.badge_flags.waiting_approval &&
          !updatedPass.badge_flags.missed
        )
      case passConstants.PASS_TYPE_NAMES.ACTIVE:
        // Filter for active passes excluding those waiting for approval or missed
        return (
          !updatedPass.badge_flags.waiting_approval &&
          !updatedPass.badge_flags.missed &&
          helpers.isPassActive(updatedPass)
        )
      case passConstants.PASS_TYPE_NAMES.MY:
        // Filter for passes that count as "my" passes
        return helpers.doesCountMyPass(updatedPass)
      case passConstants.PASS_TYPE_NAMES.MY_ACTIVE:
        // Filter for active passes that count as "my" passes
        return (
          helpers.doesCountMyPass(updatedPass) &&
          helpers.isPassActive(updatedPass)
        )
      case passConstants.PASS_TYPE_NAMES.FLAGGED:
        // Filter for flagged passes that count as "my" passes
        return (
          helpers.doesCountMyPass(updatedPass) &&
          (updatedPass.badge_flags.extended ||
            updatedPass.badge_flags.system_completed ||
            updatedPass.badge_flags.min_time)
        )
      case passConstants.PASS_TYPE_NAMES.WAITING_FOR_APPROVAL:
        // Filter for passes waiting for approval that count as "my" passes
        return (
          updatedPass.badge_flags.waiting_approval &&
          helpers.doesCountMyPass(updatedPass) &&
          !(updatedPass.badge_flags.missed || updatedPass.badge_flags.canceled)
        )
      default:
        return false
    }
  },
  updateAppointmentMetrics: (pass, type) => {
    const isToday = helpers.filterAptPassesByTab(pass, "appointments_today")
    const isAwaitingToday = helpers.filterAptPassesByTab(
      pass,
      "appointments_awaiting"
    )
    const isFuture = helpers.filterAptPassesByTab(
      pass,
      "appointments_future_awaiting"
    )
    if (isToday) {
      store.commit("dashboardCounters/UPDATE_APPOINTMENT_METRICS", {
        key: passConstants.APPOINTMENT_METRICS_KEY.TODAY,
        type
      })
    }
    if (isAwaitingToday) {
      store.commit("dashboardCounters/UPDATE_APPOINTMENT_METRICS", {
        key: passConstants.APPOINTMENT_METRICS_KEY.AWAITING_TODAY,
        type
      })
    }
    if (isFuture) {
      store.commit("dashboardCounters/UPDATE_APPOINTMENT_METRICS", {
        key: passConstants.APPOINTMENT_METRICS_KEY.FUTURE,
        type
      })
    }
  }
}

export default helpers
